<template>
  <div class="info">
    <div class="box">
      <div class="header">
        <img src="../assets/image/zhibaiTitle.png" alt="" />
        <span>新一代企业官网DIY自定义智能摆放系统</span>
      </div>
      <div class="list">
        <div class="item" v-if="userInfo.type == 'admin'" @click="$router.push('/admin/home/data')">
          <img src="../assets/image/title.gif" alt="" />
          <div class="title">管理员</div>
          <div class="desc">超级管理员用户配置</div>
          <div class="button">进入</div>
        </div>
        <div class="item" @click="$router.push('/managet')">
          <img src="../assets/image/title.gif" alt="" />
          <div class="title">控制台</div>
          <div class="desc">去创建自己的项目</div>
          <div class="button">进入</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.getUserName()
  },
  methods: {
    async getUserName() {
      const response = await http.POST('/jw-admin', {
        module: 'admin',
        operation: 'getUserInfo',
        id: localStorage.getItem('uid')
      })
      this.userInfo = response
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: #f9f9f9;

  .box {
    width: 1300px;
    margin: 0 auto;
    .header {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      > img {
        width: 250px;
        height: 100px;
        vertical-align: middle;
      }
      > span {
        font-size: 20px;
        margin-top: 16px;
        font-size: 16px;
        color: #203775;
        display: inline-block;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    .list {
      width: 100%;
      margin: 80px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        width: 30%;
        max-width: 416px;
        height: 557px;
        border: 1px solid transparent;
        margin-right: 5%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
        text-align: center;
        margin-bottom: 50px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          border-color: #155bd4;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        > img {
          margin-top: 70px;
          max-height: 188px;
        }
        .title {
          font-size: 36px;
          color: #343434;
          line-height: 48px;
          margin-top: 50px;
        }
        .desc {
          font-size: 16px;
          color: #333333;
          line-height: 48px;
        }
        .button {
          width: 170px;
          height: 49px;
          line-height: 49px;
          text-align: center;
          color: #fff;
          font-size: 18px;
          background-color: #155bd4;
          border-radius: 4px;
          margin: 35px auto 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
